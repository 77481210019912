//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import { createCertification, updateCertification, deleteCertification } from "@/lib/polkadotProvider/command/doctors/certifications";
import serviceHandler from "@/mixins/serviceHandler";
import Dialog from "@/components/Dialog";
import Button from "@/components/Button";
import { upload } from "@/lib/ipfs";
export default {
  name: "DoctorCertification",
  components: {
    Dialog,
    Button
  },
  mixins: [serviceHandler],
  data: () => ({
    certId: "",
    // for update certification
    certTitle: "",
    certIssuer: "",
    certMonth: "",
    certYear: "",
    certDescription: "",
    certSupportingDocumentsUrl: "",
    selectMonths: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    certificationDialog: false,
    isUploading: false,
    isEditCertificationDialog: false
  }),
  computed: {
    ...mapGetters({
      api: "substrate/getAPI",
      pair: "substrate/wallet",
      doctorAccount: "substrate/doctorAccount"
    }),
    selectYears() {
      const years = [];
      const thisYear = new Date().getFullYear();
      for (let i = thisYear; i >= 2000; i--) {
        years.push(String(i));
      }
      return years;
    },
    supportingDocumentsRules() {
      return [file => !file || file.type == "application/pdf" || "Document type should be application/pdf", file => !file || file.size <= 2000000 || "The total file size uploaded exceeds the maximum file size allowed (2MB)"];
    },
    titleRules() {
      return [val => !!val || "This field is required", val => val && val.length <= 50 || "This field only allows 50 characters"];
    },
    issuerRules() {
      return [val => !!val || "This field is required", val => val && val.length <= 100 || "This field only allows 100 characters"];
    },
    monthRules() {
      return [val => !!val || "This field is required"];
    },
    yearRules() {
      return [val => !!val || "This field is required"];
    },
    descriptionRules() {
      return [val => !!val || "This field is required", val => val && val.length <= 255 || "This field only allows 255 characters"];
    }
  },
  methods: {
    openCertificationDialog() {
      this.certificationDialog = true;
    },
    closeCertificationDialog() {
      this.certId = "";
      this.certificationDialog = false;
      this.isEditCertificationDialog = false;
      this.$refs.certificationForm.reset();
    },
    async submitCertification() {
      if (!this.certId) {
        await this.addCertification();
        return;
      }
      await this.updateCertification();
    },
    async addCertification() {
      if (!this.$refs.certificationForm.validate()) {
        return;
      }
      const certificationInfo = {
        title: this.certTitle,
        issuer: this.certIssuer,
        month: this.certMonth,
        year: this.certYear,
        description: this.certDescription,
        supportingDocument: this.certSupportingDocumentsUrl
      };
      await this.dispatch(createCertification, this.api, this.pair, certificationInfo, () => {
        this.closeCertificationDialog();
      });
    },
    editCertification(cert) {
      this.certId = cert.id;
      this.certTitle = cert.info.title;
      this.certIssuer = cert.info.issuer;
      this.certMonth = cert.info.month;
      this.certYear = cert.info.year;
      this.certDescription = cert.info.description;
      this.certSupportingDocumentsUrl = cert.info.supportingDocument;
      this.certificationDialog = true;
      this.isEditCertificationDialog = true;
    },
    async updateCertification() {
      if (!this.$refs.certificationForm.validate()) {
        return;
      }
      const certificationInfo = {
        title: this.certTitle,
        issuer: this.certIssuer,
        month: this.certMonth,
        year: this.certYear,
        description: this.certDescription,
        supportingDocument: this.certSupportingDocumentsUrl
      };
      await this.dispatch(updateCertification, this.api, this.pair, this.certId, certificationInfo, () => {
        this.closeCertificationDialog();
      });
    },
    async deleteCertification(cert) {
      const isConfirmed = confirm("Are you sure you want to delete this certification?");
      if (isConfirmed) {
        await this.dispatch(deleteCertification, this.api, this.pair, cert.id);
      }
    },
    fileUploadEventListener(file) {
      this.certSupportingDocumentsUrl = "";
      if (!this.$refs.certificationForm.validate()) {
        return;
      }
      if (file && file.name) {
        if (file.name.lastIndexOf(".") <= 0) {
          return;
        }
        this.isUploading = true;
        this.isLoading = true;
        const fr = new FileReader();
        fr.readAsArrayBuffer(file);
        const context = this;
        fr.addEventListener("load", async () => {
          // Upload
          const uploaded = await upload({
            fileChunk: fr.result,
            fileType: file.type,
            fileName: file.name
          });
          const computeLink = "".concat(uploaded.ipfsPath[0].data.ipfsFilePath, "/").concat(uploaded.fileName);
          context.certSupportingDocumentsUrl = "https://ipfs.io/ipfs/".concat(computeLink); // this is an image file that can be sent to server...
          context.isUploading = false;
          context.isLoading = false;
        });
      }
    }
  }
};