import { doctorCommandCallback } from "@/lib/polkadotProvider/command/doctors";
export async function createCertification(api, pair, data) {
  let callback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => {};
  const unsub = await api.tx.doctorCertifications.createCertification(data).signAndSend(pair, {
    nonce: -1
  }, async _ref => {
    let {
      events = [],
      status
    } = _ref;
    await doctorCommandCallback(api, pair, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function updateCertification(api, pair, certificationId, data) {
  let callback = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : () => {};
  const unsub = await api.tx.doctorCertifications.updateCertification(certificationId, data).signAndSend(pair, {
    nonce: -1
  }, async _ref2 => {
    let {
      events = [],
      status
    } = _ref2;
    await doctorCommandCallback(api, pair, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function deleteCertification(api, pair, certificationId) {
  let callback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => {};
  const unsub = await api.tx.doctorCertifications.deleteCertification(certificationId).signAndSend(pair, {
    nonce: -1
  }, async _ref3 => {
    let {
      events = [],
      status
    } = _ref3;
    await doctorCommandCallback(api, pair, {
      events,
      status,
      callback,
      unsub
    });
  });
}